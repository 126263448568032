// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-bumi-serpong-damai-en-js": () => import("./../../../src/pages/about-bumi-serpong-damai.en.js" /* webpackChunkName: "component---src-pages-about-bumi-serpong-damai-en-js" */),
  "component---src-pages-about-bumi-serpong-damai-id-js": () => import("./../../../src/pages/about-bumi-serpong-damai.id.js" /* webpackChunkName: "component---src-pages-about-bumi-serpong-damai-id-js" */),
  "component---src-pages-about-duta-pertiwi-en-js": () => import("./../../../src/pages/about-duta-pertiwi.en.js" /* webpackChunkName: "component---src-pages-about-duta-pertiwi-en-js" */),
  "component---src-pages-about-duta-pertiwi-id-js": () => import("./../../../src/pages/about-duta-pertiwi.id.js" /* webpackChunkName: "component---src-pages-about-duta-pertiwi-id-js" */),
  "component---src-pages-about-us-awards-en-js": () => import("./../../../src/pages/about-us/awards.en.js" /* webpackChunkName: "component---src-pages-about-us-awards-en-js" */),
  "component---src-pages-about-us-awards-id-js": () => import("./../../../src/pages/about-us/awards.id.js" /* webpackChunkName: "component---src-pages-about-us-awards-id-js" */),
  "component---src-pages-about-us-board-of-directors-bsd-en-js": () => import("./../../../src/pages/about-us/board-of-directors-bsd.en.js" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-bsd-en-js" */),
  "component---src-pages-about-us-board-of-directors-bsd-id-js": () => import("./../../../src/pages/about-us/board-of-directors-bsd.id.js" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-bsd-id-js" */),
  "component---src-pages-about-us-board-of-directors-duti-en-js": () => import("./../../../src/pages/about-us/board-of-directors-duti.en.js" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-duti-en-js" */),
  "component---src-pages-about-us-board-of-directors-duti-id-js": () => import("./../../../src/pages/about-us/board-of-directors-duti.id.js" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-duti-id-js" */),
  "component---src-pages-about-us-board-of-directors-en-js": () => import("./../../../src/pages/about-us/board-of-directors.en.js" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-en-js" */),
  "component---src-pages-about-us-board-of-directors-id-js": () => import("./../../../src/pages/about-us/board-of-directors.id.js" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-id-js" */),
  "component---src-pages-about-us-history-bumi-serpong-damai-en-js": () => import("./../../../src/pages/about-us/history-bumi-serpong-damai.en.js" /* webpackChunkName: "component---src-pages-about-us-history-bumi-serpong-damai-en-js" */),
  "component---src-pages-about-us-history-bumi-serpong-damai-id-js": () => import("./../../../src/pages/about-us/history-bumi-serpong-damai.id.js" /* webpackChunkName: "component---src-pages-about-us-history-bumi-serpong-damai-id-js" */),
  "component---src-pages-about-us-history-duta-pertiwi-en-js": () => import("./../../../src/pages/about-us/history-duta-pertiwi.en.js" /* webpackChunkName: "component---src-pages-about-us-history-duta-pertiwi-en-js" */),
  "component---src-pages-about-us-history-duta-pertiwi-id-js": () => import("./../../../src/pages/about-us/history-duta-pertiwi.id.js" /* webpackChunkName: "component---src-pages-about-us-history-duta-pertiwi-id-js" */),
  "component---src-pages-about-us-history-en-js": () => import("./../../../src/pages/about-us/history.en.js" /* webpackChunkName: "component---src-pages-about-us-history-en-js" */),
  "component---src-pages-about-us-history-id-js": () => import("./../../../src/pages/about-us/history.id.js" /* webpackChunkName: "component---src-pages-about-us-history-id-js" */),
  "component---src-pages-about-us-index-en-js": () => import("./../../../src/pages/about-us/index.en.js" /* webpackChunkName: "component---src-pages-about-us-index-en-js" */),
  "component---src-pages-about-us-index-id-js": () => import("./../../../src/pages/about-us/index.id.js" /* webpackChunkName: "component---src-pages-about-us-index-id-js" */),
  "component---src-pages-about-us-overseas-business-en-js": () => import("./../../../src/pages/about-us/overseas-business.en.js" /* webpackChunkName: "component---src-pages-about-us-overseas-business-en-js" */),
  "component---src-pages-about-us-overseas-business-id-js": () => import("./../../../src/pages/about-us/overseas-business.id.js" /* webpackChunkName: "component---src-pages-about-us-overseas-business-id-js" */),
  "component---src-pages-about-us-structure-organization-bsd-en-js": () => import("./../../../src/pages/about-us/structure-organization-bsd.en.js" /* webpackChunkName: "component---src-pages-about-us-structure-organization-bsd-en-js" */),
  "component---src-pages-about-us-structure-organization-bsd-id-js": () => import("./../../../src/pages/about-us/structure-organization-bsd.id.js" /* webpackChunkName: "component---src-pages-about-us-structure-organization-bsd-id-js" */),
  "component---src-pages-about-us-structure-organization-duti-en-js": () => import("./../../../src/pages/about-us/structure-organization-duti.en.js" /* webpackChunkName: "component---src-pages-about-us-structure-organization-duti-en-js" */),
  "component---src-pages-about-us-structure-organization-duti-id-js": () => import("./../../../src/pages/about-us/structure-organization-duti.id.js" /* webpackChunkName: "component---src-pages-about-us-structure-organization-duti-id-js" */),
  "component---src-pages-about-us-structure-organization-en-js": () => import("./../../../src/pages/about-us/structure-organization.en.js" /* webpackChunkName: "component---src-pages-about-us-structure-organization-en-js" */),
  "component---src-pages-about-us-structure-organization-id-js": () => import("./../../../src/pages/about-us/structure-organization.id.js" /* webpackChunkName: "component---src-pages-about-us-structure-organization-id-js" */),
  "component---src-pages-apartemen-add-index-en-js": () => import("./../../../src/pages/apartemen-add/index.en.js" /* webpackChunkName: "component---src-pages-apartemen-add-index-en-js" */),
  "component---src-pages-apartemen-add-spin-index-en-js": () => import("./../../../src/pages/apartemen-add/spin/index.en.js" /* webpackChunkName: "component---src-pages-apartemen-add-spin-index-en-js" */),
  "component---src-pages-bca-expo-index-en-js": () => import("./../../../src/pages/bca-expo/index.en.js" /* webpackChunkName: "component---src-pages-bca-expo-index-en-js" */),
  "component---src-pages-bca-expo-index-id-js": () => import("./../../../src/pages/bca-expo/index.id.js" /* webpackChunkName: "component---src-pages-bca-expo-index-id-js" */),
  "component---src-pages-calendar-2022-index-en-js": () => import("./../../../src/pages/calendar2022/index.en.js" /* webpackChunkName: "component---src-pages-calendar-2022-index-en-js" */),
  "component---src-pages-calendar-2023-index-en-js": () => import("./../../../src/pages/calendar2023/index.en.js" /* webpackChunkName: "component---src-pages-calendar-2023-index-en-js" */),
  "component---src-pages-calendar-2024-index-en-js": () => import("./../../../src/pages/calendar2024/index.en.js" /* webpackChunkName: "component---src-pages-calendar-2024-index-en-js" */),
  "component---src-pages-calendar-2025-index-en-js": () => import("./../../../src/pages/calendar2025/index.en.js" /* webpackChunkName: "component---src-pages-calendar-2025-index-en-js" */),
  "component---src-pages-career-index-en-js": () => import("./../../../src/pages/career/index.en.js" /* webpackChunkName: "component---src-pages-career-index-en-js" */),
  "component---src-pages-career-index-id-js": () => import("./../../../src/pages/career/index.id.js" /* webpackChunkName: "component---src-pages-career-index-id-js" */),
  "component---src-pages-contact-us-index-en-js": () => import("./../../../src/pages/contact-us/index.en.js" /* webpackChunkName: "component---src-pages-contact-us-index-en-js" */),
  "component---src-pages-contact-us-index-id-js": () => import("./../../../src/pages/contact-us/index.id.js" /* webpackChunkName: "component---src-pages-contact-us-index-id-js" */),
  "component---src-pages-development-commercial-index-en-js": () => import("./../../../src/pages/development/commercial/index.en.js" /* webpackChunkName: "component---src-pages-development-commercial-index-en-js" */),
  "component---src-pages-development-commercial-index-id-js": () => import("./../../../src/pages/development/commercial/index.id.js" /* webpackChunkName: "component---src-pages-development-commercial-index-id-js" */),
  "component---src-pages-development-hospitality-index-en-js": () => import("./../../../src/pages/development/hospitality/index.en.js" /* webpackChunkName: "component---src-pages-development-hospitality-index-en-js" */),
  "component---src-pages-development-hospitality-index-id-js": () => import("./../../../src/pages/development/hospitality/index.id.js" /* webpackChunkName: "component---src-pages-development-hospitality-index-id-js" */),
  "component---src-pages-development-index-en-js": () => import("./../../../src/pages/development/index.en.js" /* webpackChunkName: "component---src-pages-development-index-en-js" */),
  "component---src-pages-development-index-id-js": () => import("./../../../src/pages/development/index.id.js" /* webpackChunkName: "component---src-pages-development-index-id-js" */),
  "component---src-pages-development-industrial-index-en-js": () => import("./../../../src/pages/development/industrial/index.en.js" /* webpackChunkName: "component---src-pages-development-industrial-index-en-js" */),
  "component---src-pages-development-industrial-index-id-js": () => import("./../../../src/pages/development/industrial/index.id.js" /* webpackChunkName: "component---src-pages-development-industrial-index-id-js" */),
  "component---src-pages-development-kavling-index-en-js": () => import("./../../../src/pages/development/kavling/index.en.js" /* webpackChunkName: "component---src-pages-development-kavling-index-en-js" */),
  "component---src-pages-development-kavling-index-id-js": () => import("./../../../src/pages/development/kavling/index.id.js" /* webpackChunkName: "component---src-pages-development-kavling-index-id-js" */),
  "component---src-pages-development-office-index-en-js": () => import("./../../../src/pages/development/office/index.en.js" /* webpackChunkName: "component---src-pages-development-office-index-en-js" */),
  "component---src-pages-development-office-index-id-js": () => import("./../../../src/pages/development/office/index.id.js" /* webpackChunkName: "component---src-pages-development-office-index-id-js" */),
  "component---src-pages-development-residential-index-en-js": () => import("./../../../src/pages/development/residential/index.en.js" /* webpackChunkName: "component---src-pages-development-residential-index-en-js" */),
  "component---src-pages-development-residential-index-id-js": () => import("./../../../src/pages/development/residential/index.id.js" /* webpackChunkName: "component---src-pages-development-residential-index-id-js" */),
  "component---src-pages-development-retail-index-en-js": () => import("./../../../src/pages/development/retail/index.en.js" /* webpackChunkName: "component---src-pages-development-retail-index-en-js" */),
  "component---src-pages-development-retail-index-id-js": () => import("./../../../src/pages/development/retail/index.id.js" /* webpackChunkName: "component---src-pages-development-retail-index-id-js" */),
  "component---src-pages-development-township-bsd-city-bus-station-en-js": () => import("./../../../src/pages/development/township/bsd-city/bus-station.en.js" /* webpackChunkName: "component---src-pages-development-township-bsd-city-bus-station-en-js" */),
  "component---src-pages-development-township-bsd-city-bus-station-id-js": () => import("./../../../src/pages/development/township/bsd-city/bus-station.id.js" /* webpackChunkName: "component---src-pages-development-township-bsd-city-bus-station-id-js" */),
  "component---src-pages-development-township-bsd-city-toll-road-en-js": () => import("./../../../src/pages/development/township/bsd-city/toll-road.en.js" /* webpackChunkName: "component---src-pages-development-township-bsd-city-toll-road-en-js" */),
  "component---src-pages-development-township-bsd-city-toll-road-id-js": () => import("./../../../src/pages/development/township/bsd-city/toll-road.id.js" /* webpackChunkName: "component---src-pages-development-township-bsd-city-toll-road-id-js" */),
  "component---src-pages-development-township-bsd-city-train-station-en-js": () => import("./../../../src/pages/development/township/bsd-city/train-station.en.js" /* webpackChunkName: "component---src-pages-development-township-bsd-city-train-station-en-js" */),
  "component---src-pages-development-township-bsd-city-train-station-id-js": () => import("./../../../src/pages/development/township/bsd-city/train-station.id.js" /* webpackChunkName: "component---src-pages-development-township-bsd-city-train-station-id-js" */),
  "component---src-pages-development-township-index-en-js": () => import("./../../../src/pages/development/township/index.en.js" /* webpackChunkName: "component---src-pages-development-township-index-en-js" */),
  "component---src-pages-development-township-index-id-js": () => import("./../../../src/pages/development/township/index.id.js" /* webpackChunkName: "component---src-pages-development-township-index-id-js" */),
  "component---src-pages-double-luckydraw-february-index-en-js": () => import("./../../../src/pages/double-luckydraw-february/index.en.js" /* webpackChunkName: "component---src-pages-double-luckydraw-february-index-en-js" */),
  "component---src-pages-double-luckydraw-february-pop-component-desktop-js": () => import("./../../../src/pages/double-luckydraw-february/pop/component/desktop.js" /* webpackChunkName: "component---src-pages-double-luckydraw-february-pop-component-desktop-js" */),
  "component---src-pages-double-luckydraw-february-pop-component-mobile-js": () => import("./../../../src/pages/double-luckydraw-february/pop/component/mobile.js" /* webpackChunkName: "component---src-pages-double-luckydraw-february-pop-component-mobile-js" */),
  "component---src-pages-double-luckydraw-february-pop-index-en-js": () => import("./../../../src/pages/double-luckydraw-february/pop/index.en.js" /* webpackChunkName: "component---src-pages-double-luckydraw-february-pop-index-en-js" */),
  "component---src-pages-double-luckydraw-february-thankyou-js": () => import("./../../../src/pages/double-luckydraw-february/thankyou.js" /* webpackChunkName: "component---src-pages-double-luckydraw-february-thankyou-js" */),
  "component---src-pages-double-luckydraw-index-en-js": () => import("./../../../src/pages/double-luckydraw/index.en.js" /* webpackChunkName: "component---src-pages-double-luckydraw-index-en-js" */),
  "component---src-pages-double-luckydraw-pop-component-desktop-js": () => import("./../../../src/pages/double-luckydraw/pop/component/desktop.js" /* webpackChunkName: "component---src-pages-double-luckydraw-pop-component-desktop-js" */),
  "component---src-pages-double-luckydraw-pop-component-mobile-js": () => import("./../../../src/pages/double-luckydraw/pop/component/mobile.js" /* webpackChunkName: "component---src-pages-double-luckydraw-pop-component-mobile-js" */),
  "component---src-pages-double-luckydraw-pop-index-en-js": () => import("./../../../src/pages/double-luckydraw/pop/index.en.js" /* webpackChunkName: "component---src-pages-double-luckydraw-pop-index-en-js" */),
  "component---src-pages-double-luckydraw-thankyou-js": () => import("./../../../src/pages/double-luckydraw/thankyou.js" /* webpackChunkName: "component---src-pages-double-luckydraw-thankyou-js" */),
  "component---src-pages-enchante-add-index-en-js": () => import("./../../../src/pages/enchante-add/index.en.js" /* webpackChunkName: "component---src-pages-enchante-add-index-en-js" */),
  "component---src-pages-enchante-add-spin-index-en-js": () => import("./../../../src/pages/enchante-add/spin/index.en.js" /* webpackChunkName: "component---src-pages-enchante-add-spin-index-en-js" */),
  "component---src-pages-extraketupat-index-en-js": () => import("./../../../src/pages/extraketupat/index.en.js" /* webpackChunkName: "component---src-pages-extraketupat-index-en-js" */),
  "component---src-pages-extraketupat-pop-index-en-js": () => import("./../../../src/pages/extraketupat/pop/index.en.js" /* webpackChunkName: "component---src-pages-extraketupat-pop-index-en-js" */),
  "component---src-pages-extrasurprise-index-js": () => import("./../../../src/pages/extrasurprise/index.js" /* webpackChunkName: "component---src-pages-extrasurprise-index-js" */),
  "component---src-pages-extrasurprise-pop-index-js": () => import("./../../../src/pages/extrasurprise/pop/index.js" /* webpackChunkName: "component---src-pages-extrasurprise-pop-index-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-giias-index-en-js": () => import("./../../../src/pages/giias/index.en.js" /* webpackChunkName: "component---src-pages-giias-index-en-js" */),
  "component---src-pages-iipe-index-en-js": () => import("./../../../src/pages/iipe/index.en.js" /* webpackChunkName: "component---src-pages-iipe-index-en-js" */),
  "component---src-pages-indeks-id-js": () => import("./../../../src/pages/indeks.id.js" /* webpackChunkName: "component---src-pages-indeks-id-js" */),
  "component---src-pages-indeks-js": () => import("./../../../src/pages/indeks.js" /* webpackChunkName: "component---src-pages-indeks-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-id-js": () => import("./../../../src/pages/index.id.js" /* webpackChunkName: "component---src-pages-index-id-js" */),
  "component---src-pages-infiniteliving-faq-index-en-js": () => import("./../../../src/pages/infiniteliving/faq/index.en.js" /* webpackChunkName: "component---src-pages-infiniteliving-faq-index-en-js" */),
  "component---src-pages-infiniteliving-index-en-js": () => import("./../../../src/pages/infiniteliving/index.en.js" /* webpackChunkName: "component---src-pages-infiniteliving-index-en-js" */),
  "component---src-pages-infiniteliving-index-id-js": () => import("./../../../src/pages/infiniteliving/index.id.js" /* webpackChunkName: "component---src-pages-infiniteliving-index-id-js" */),
  "component---src-pages-infiniteliving-thankyou-index-en-js": () => import("./../../../src/pages/infiniteliving/thankyou/index.en.js" /* webpackChunkName: "component---src-pages-infiniteliving-thankyou-index-en-js" */),
  "component---src-pages-investors-annual-report-en-js": () => import("./../../../src/pages/investors/annual-report.en.js" /* webpackChunkName: "component---src-pages-investors-annual-report-en-js" */),
  "component---src-pages-investors-annual-report-id-js": () => import("./../../../src/pages/investors/annual-report.id.js" /* webpackChunkName: "component---src-pages-investors-annual-report-id-js" */),
  "component---src-pages-investors-corporate-governance-en-js": () => import("./../../../src/pages/investors/corporate-governance.en.js" /* webpackChunkName: "component---src-pages-investors-corporate-governance-en-js" */),
  "component---src-pages-investors-corporate-governance-id-js": () => import("./../../../src/pages/investors/corporate-governance.id.js" /* webpackChunkName: "component---src-pages-investors-corporate-governance-id-js" */),
  "component---src-pages-investors-corporate-structure-en-js": () => import("./../../../src/pages/investors/corporate-structure.en.js" /* webpackChunkName: "component---src-pages-investors-corporate-structure-en-js" */),
  "component---src-pages-investors-corporate-structure-id-js": () => import("./../../../src/pages/investors/corporate-structure.id.js" /* webpackChunkName: "component---src-pages-investors-corporate-structure-id-js" */),
  "component---src-pages-investors-financial-reports-en-js": () => import("./../../../src/pages/investors/financial-reports.en.js" /* webpackChunkName: "component---src-pages-investors-financial-reports-en-js" */),
  "component---src-pages-investors-financial-reports-id-js": () => import("./../../../src/pages/investors/financial-reports.id.js" /* webpackChunkName: "component---src-pages-investors-financial-reports-id-js" */),
  "component---src-pages-investors-index-en-js": () => import("./../../../src/pages/investors/index.en.js" /* webpackChunkName: "component---src-pages-investors-index-en-js" */),
  "component---src-pages-investors-index-id-js": () => import("./../../../src/pages/investors/index.id.js" /* webpackChunkName: "component---src-pages-investors-index-id-js" */),
  "component---src-pages-investors-information-disclosure-statement-en-js": () => import("./../../../src/pages/investors/information-disclosure-statement.en.js" /* webpackChunkName: "component---src-pages-investors-information-disclosure-statement-en-js" */),
  "component---src-pages-investors-information-disclosure-statement-id-js": () => import("./../../../src/pages/investors/information-disclosure-statement.id.js" /* webpackChunkName: "component---src-pages-investors-information-disclosure-statement-id-js" */),
  "component---src-pages-investors-investor-relations-en-js": () => import("./../../../src/pages/investors/investor-relations.en.js" /* webpackChunkName: "component---src-pages-investors-investor-relations-en-js" */),
  "component---src-pages-investors-investor-relations-id-js": () => import("./../../../src/pages/investors/investor-relations.id.js" /* webpackChunkName: "component---src-pages-investors-investor-relations-id-js" */),
  "component---src-pages-investors-press-release-en-js": () => import("./../../../src/pages/investors/press-release.en.js" /* webpackChunkName: "component---src-pages-investors-press-release-en-js" */),
  "component---src-pages-investors-press-release-id-js": () => import("./../../../src/pages/investors/press-release.id.js" /* webpackChunkName: "component---src-pages-investors-press-release-id-js" */),
  "component---src-pages-investors-public-expose-en-js": () => import("./../../../src/pages/investors/public-expose.en.js" /* webpackChunkName: "component---src-pages-investors-public-expose-en-js" */),
  "component---src-pages-investors-public-expose-id-js": () => import("./../../../src/pages/investors/public-expose.id.js" /* webpackChunkName: "component---src-pages-investors-public-expose-id-js" */),
  "component---src-pages-living-lab-ventures-index-en-js": () => import("./../../../src/pages/living-lab-ventures/index.en.js" /* webpackChunkName: "component---src-pages-living-lab-ventures-index-en-js" */),
  "component---src-pages-living-lab-ventures-index-id-js": () => import("./../../../src/pages/living-lab-ventures/index.id.js" /* webpackChunkName: "component---src-pages-living-lab-ventures-index-id-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-luckydip-index-en-js": () => import("./../../../src/pages/luckydip/index.en.js" /* webpackChunkName: "component---src-pages-luckydip-index-en-js" */),
  "component---src-pages-luckydip-pop-index-en-js": () => import("./../../../src/pages/luckydip/pop/index.en.js" /* webpackChunkName: "component---src-pages-luckydip-pop-index-en-js" */),
  "component---src-pages-maymorable-index-en-js": () => import("./../../../src/pages/maymorable/index.en.js" /* webpackChunkName: "component---src-pages-maymorable-index-en-js" */),
  "component---src-pages-move-in-quickly-faq-index-en-js": () => import("./../../../src/pages/move-in-quickly/faq/index.en.js" /* webpackChunkName: "component---src-pages-move-in-quickly-faq-index-en-js" */),
  "component---src-pages-move-in-quickly-index-en-js": () => import("./../../../src/pages/move-in-quickly/index.en.js" /* webpackChunkName: "component---src-pages-move-in-quickly-index-en-js" */),
  "component---src-pages-move-in-quickly-index-id-js": () => import("./../../../src/pages/move-in-quickly/index.id.js" /* webpackChunkName: "component---src-pages-move-in-quickly-index-id-js" */),
  "component---src-pages-move-in-quickly-thankyou-index-en-js": () => import("./../../../src/pages/move-in-quickly/thankyou/index.en.js" /* webpackChunkName: "component---src-pages-move-in-quickly-thankyou-index-en-js" */),
  "component---src-pages-moveinquickly-faq-index-en-js": () => import("./../../../src/pages/moveinquickly/faq/index.en.js" /* webpackChunkName: "component---src-pages-moveinquickly-faq-index-en-js" */),
  "component---src-pages-moveinquickly-index-en-js": () => import("./../../../src/pages/moveinquickly/index.en.js" /* webpackChunkName: "component---src-pages-moveinquickly-index-en-js" */),
  "component---src-pages-moveinquickly-index-id-js": () => import("./../../../src/pages/moveinquickly/index.id.js" /* webpackChunkName: "component---src-pages-moveinquickly-index-id-js" */),
  "component---src-pages-moveinquickly-thankyou-index-en-js": () => import("./../../../src/pages/moveinquickly/thankyou/index.en.js" /* webpackChunkName: "component---src-pages-moveinquickly-thankyou-index-en-js" */),
  "component---src-pages-news-blog-en-js": () => import("./../../../src/pages/news/blog.en.js" /* webpackChunkName: "component---src-pages-news-blog-en-js" */),
  "component---src-pages-news-blog-id-js": () => import("./../../../src/pages/news/blog.id.js" /* webpackChunkName: "component---src-pages-news-blog-id-js" */),
  "component---src-pages-news-events-en-js": () => import("./../../../src/pages/news/events.en.js" /* webpackChunkName: "component---src-pages-news-events-en-js" */),
  "component---src-pages-news-events-id-js": () => import("./../../../src/pages/news/events.id.js" /* webpackChunkName: "component---src-pages-news-events-id-js" */),
  "component---src-pages-news-index-en-js": () => import("./../../../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-id-js": () => import("./../../../src/pages/news/index.id.js" /* webpackChunkName: "component---src-pages-news-index-id-js" */),
  "component---src-pages-news-press-release-en-js": () => import("./../../../src/pages/news/press-release.en.js" /* webpackChunkName: "component---src-pages-news-press-release-en-js" */),
  "component---src-pages-news-press-release-id-js": () => import("./../../../src/pages/news/press-release.id.js" /* webpackChunkName: "component---src-pages-news-press-release-id-js" */),
  "component---src-pages-news-sustainability-en-js": () => import("./../../../src/pages/news/sustainability.en.js" /* webpackChunkName: "component---src-pages-news-sustainability-en-js" */),
  "component---src-pages-news-sustainability-id-js": () => import("./../../../src/pages/news/sustainability.id.js" /* webpackChunkName: "component---src-pages-news-sustainability-id-js" */),
  "component---src-pages-partner-index-en-js": () => import("./../../../src/pages/partner/index.en.js" /* webpackChunkName: "component---src-pages-partner-index-en-js" */),
  "component---src-pages-partner-index-id-js": () => import("./../../../src/pages/partner/index.id.js" /* webpackChunkName: "component---src-pages-partner-index-id-js" */),
  "component---src-pages-precious-bronze-en-js": () => import("./../../../src/pages/precious-bronze.en.js" /* webpackChunkName: "component---src-pages-precious-bronze-en-js" */),
  "component---src-pages-precious-bronze-id-js": () => import("./../../../src/pages/precious-bronze.id.js" /* webpackChunkName: "component---src-pages-precious-bronze-id-js" */),
  "component---src-pages-precious-gold-en-js": () => import("./../../../src/pages/precious-gold.en.js" /* webpackChunkName: "component---src-pages-precious-gold-en-js" */),
  "component---src-pages-precious-gold-id-js": () => import("./../../../src/pages/precious-gold.id.js" /* webpackChunkName: "component---src-pages-precious-gold-id-js" */),
  "component---src-pages-precious-index-en-js": () => import("./../../../src/pages/precious/index.en.js" /* webpackChunkName: "component---src-pages-precious-index-en-js" */),
  "component---src-pages-precious-index-id-js": () => import("./../../../src/pages/precious/index.id.js" /* webpackChunkName: "component---src-pages-precious-index-id-js" */),
  "component---src-pages-precious-platinum-en-js": () => import("./../../../src/pages/precious-platinum.en.js" /* webpackChunkName: "component---src-pages-precious-platinum-en-js" */),
  "component---src-pages-precious-platinum-id-js": () => import("./../../../src/pages/precious-platinum.id.js" /* webpackChunkName: "component---src-pages-precious-platinum-id-js" */),
  "component---src-pages-precious-silver-en-js": () => import("./../../../src/pages/precious-silver.en.js" /* webpackChunkName: "component---src-pages-precious-silver-en-js" */),
  "component---src-pages-precious-silver-id-js": () => import("./../../../src/pages/precious-silver.id.js" /* webpackChunkName: "component---src-pages-precious-silver-id-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-id-js": () => import("./../../../src/pages/privacy-policy.id.js" /* webpackChunkName: "component---src-pages-privacy-policy-id-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-promo-karyawan-sinarmas-group-details-index-en-js": () => import("./../../../src/pages/promo-karyawan-sinarmas-group/details/index.en.js" /* webpackChunkName: "component---src-pages-promo-karyawan-sinarmas-group-details-index-en-js" */),
  "component---src-pages-promo-karyawan-sinarmas-group-index-en-js": () => import("./../../../src/pages/promo-karyawan-sinarmas-group/index.en.js" /* webpackChunkName: "component---src-pages-promo-karyawan-sinarmas-group-index-en-js" */),
  "component---src-pages-promo-karyawan-sinarmas-group-index-id-js": () => import("./../../../src/pages/promo-karyawan-sinarmas-group/index.id.js" /* webpackChunkName: "component---src-pages-promo-karyawan-sinarmas-group-index-id-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-savetember-index-en-js": () => import("./../../../src/pages/savetember/index.en.js" /* webpackChunkName: "component---src-pages-savetember-index-en-js" */),
  "component---src-pages-savetember-pop-index-en-js": () => import("./../../../src/pages/savetember/pop/index.en.js" /* webpackChunkName: "component---src-pages-savetember-pop-index-en-js" */),
  "component---src-pages-search-price-result-en-js": () => import("./../../../src/pages/search-price-result.en.js" /* webpackChunkName: "component---src-pages-search-price-result-en-js" */),
  "component---src-pages-search-price-result-id-js": () => import("./../../../src/pages/search-price-result.id.js" /* webpackChunkName: "component---src-pages-search-price-result-id-js" */),
  "component---src-pages-search-result-id-js": () => import("./../../../src/pages/search-result.id.js" /* webpackChunkName: "component---src-pages-search-result-id-js" */),
  "component---src-pages-search-result-js": () => import("./../../../src/pages/search-result.js" /* webpackChunkName: "component---src-pages-search-result-js" */),
  "component---src-pages-socialmedia-index-js": () => import("./../../../src/pages/socialmedia/index.js" /* webpackChunkName: "component---src-pages-socialmedia-index-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-sustainability-best-in-class-real-estate-js": () => import("./../../../src/pages/sustainability/best-in-class-real-estate.js" /* webpackChunkName: "component---src-pages-sustainability-best-in-class-real-estate-js" */),
  "component---src-pages-sustainability-climate-change-and-the-environment-js": () => import("./../../../src/pages/sustainability/climate-change-and-the-environment.js" /* webpackChunkName: "component---src-pages-sustainability-climate-change-and-the-environment-js" */),
  "component---src-pages-sustainability-economy-en-js": () => import("./../../../src/pages/sustainability/economy.en.js" /* webpackChunkName: "component---src-pages-sustainability-economy-en-js" */),
  "component---src-pages-sustainability-economy-id-js": () => import("./../../../src/pages/sustainability/economy.id.js" /* webpackChunkName: "component---src-pages-sustainability-economy-id-js" */),
  "component---src-pages-sustainability-education-patronage-js": () => import("./../../../src/pages/sustainability/education-patronage.js" /* webpackChunkName: "component---src-pages-sustainability-education-patronage-js" */),
  "component---src-pages-sustainability-environment-en-js": () => import("./../../../src/pages/sustainability/environment.en.js" /* webpackChunkName: "component---src-pages-sustainability-environment-en-js" */),
  "component---src-pages-sustainability-environment-id-js": () => import("./../../../src/pages/sustainability/environment.id.js" /* webpackChunkName: "component---src-pages-sustainability-environment-id-js" */),
  "component---src-pages-sustainability-green-initiative-index-en-js": () => import("./../../../src/pages/sustainability/green-initiative/index.en.js" /* webpackChunkName: "component---src-pages-sustainability-green-initiative-index-en-js" */),
  "component---src-pages-sustainability-green-initiative-index-id-js": () => import("./../../../src/pages/sustainability/green-initiative/index.id.js" /* webpackChunkName: "component---src-pages-sustainability-green-initiative-index-id-js" */),
  "component---src-pages-sustainability-index-en-js": () => import("./../../../src/pages/sustainability/index.en.js" /* webpackChunkName: "component---src-pages-sustainability-index-en-js" */),
  "component---src-pages-sustainability-index-id-js": () => import("./../../../src/pages/sustainability/index.id.js" /* webpackChunkName: "component---src-pages-sustainability-index-id-js" */),
  "component---src-pages-sustainability-our-sustainability-program-for-communities-js": () => import("./../../../src/pages/sustainability/our-sustainability-program-for-communities.js" /* webpackChunkName: "component---src-pages-sustainability-our-sustainability-program-for-communities-js" */),
  "component---src-pages-sustainability-report-index-en-js": () => import("./../../../src/pages/sustainability/report/index.en.js" /* webpackChunkName: "component---src-pages-sustainability-report-index-en-js" */),
  "component---src-pages-sustainability-report-index-id-js": () => import("./../../../src/pages/sustainability/report/index.id.js" /* webpackChunkName: "component---src-pages-sustainability-report-index-id-js" */),
  "component---src-pages-sustainability-social-en-js": () => import("./../../../src/pages/sustainability/social.en.js" /* webpackChunkName: "component---src-pages-sustainability-social-en-js" */),
  "component---src-pages-sustainability-social-id-js": () => import("./../../../src/pages/sustainability/social.id.js" /* webpackChunkName: "component---src-pages-sustainability-social-id-js" */),
  "component---src-pages-sustainability-sr-case-study-index-en-js": () => import("./../../../src/pages/sustainability/sr-case-study/index.en.js" /* webpackChunkName: "component---src-pages-sustainability-sr-case-study-index-en-js" */),
  "component---src-pages-sustainability-sr-case-study-index-id-js": () => import("./../../../src/pages/sustainability/sr-case-study/index.id.js" /* webpackChunkName: "component---src-pages-sustainability-sr-case-study-index-id-js" */),
  "component---src-pages-sustainability-sustainable-community-js": () => import("./../../../src/pages/sustainability/sustainable-community.js" /* webpackChunkName: "component---src-pages-sustainability-sustainable-community-js" */),
  "component---src-pages-terms-conditions-en-js": () => import("./../../../src/pages/terms-&-conditions.en.js" /* webpackChunkName: "component---src-pages-terms-conditions-en-js" */),
  "component---src-pages-terms-conditions-id-js": () => import("./../../../src/pages/terms-&-conditions.id.js" /* webpackChunkName: "component---src-pages-terms-conditions-id-js" */),
  "component---src-pages-testing-async-js": () => import("./../../../src/pages/testing-async.js" /* webpackChunkName: "component---src-pages-testing-async-js" */),
  "component---src-pages-testing-async-stg-js": () => import("./../../../src/pages/testing-async-stg.js" /* webpackChunkName: "component---src-pages-testing-async-stg-js" */),
  "component---src-pages-testing-iframe-js": () => import("./../../../src/pages/testing-iframe.js" /* webpackChunkName: "component---src-pages-testing-iframe-js" */),
  "component---src-pages-testing-js-js": () => import("./../../../src/pages/testing-js.js" /* webpackChunkName: "component---src-pages-testing-js-js" */),
  "component---src-pages-thankyou-index-en-js": () => import("./../../../src/pages/thankyou/index.en.js" /* webpackChunkName: "component---src-pages-thankyou-index-en-js" */),
  "component---src-pages-thankyou-index-id-js": () => import("./../../../src/pages/thankyou/index.id.js" /* webpackChunkName: "component---src-pages-thankyou-index-id-js" */),
  "component---src-pages-thr-luckydraw-march-index-en-js": () => import("./../../../src/pages/thr-luckydraw-march/index.en.js" /* webpackChunkName: "component---src-pages-thr-luckydraw-march-index-en-js" */),
  "component---src-pages-thr-luckydraw-march-index-id-js": () => import("./../../../src/pages/thr-luckydraw-march/index.id.js" /* webpackChunkName: "component---src-pages-thr-luckydraw-march-index-id-js" */),
  "component---src-pages-thr-luckydraw-march-pop-desktop-component-desktop-js": () => import("./../../../src/pages/thr-luckydraw-march/pop-desktop/component/desktop.js" /* webpackChunkName: "component---src-pages-thr-luckydraw-march-pop-desktop-component-desktop-js" */),
  "component---src-pages-thr-luckydraw-march-pop-desktop-index-en-js": () => import("./../../../src/pages/thr-luckydraw-march/pop-desktop/index.en.js" /* webpackChunkName: "component---src-pages-thr-luckydraw-march-pop-desktop-index-en-js" */),
  "component---src-pages-thr-luckydraw-march-pop-mobile-component-mobile-js": () => import("./../../../src/pages/thr-luckydraw-march/pop-mobile/component/mobile.js" /* webpackChunkName: "component---src-pages-thr-luckydraw-march-pop-mobile-component-mobile-js" */),
  "component---src-pages-thr-luckydraw-march-pop-mobile-index-en-js": () => import("./../../../src/pages/thr-luckydraw-march/pop-mobile/index.en.js" /* webpackChunkName: "component---src-pages-thr-luckydraw-march-pop-mobile-index-en-js" */),
  "component---src-pages-thr-luckydraw-march-thankyou-js": () => import("./../../../src/pages/thr-luckydraw-march/thankyou.js" /* webpackChunkName: "component---src-pages-thr-luckydraw-march-thankyou-js" */),
  "component---src-pages-thrketupatlebaran-index-js": () => import("./../../../src/pages/thrketupatlebaran/index.js" /* webpackChunkName: "component---src-pages-thrketupatlebaran-index-js" */),
  "component---src-pages-thrketupatlebaran-spinketupat-index-js": () => import("./../../../src/pages/thrketupatlebaran/spinketupat/index.js" /* webpackChunkName: "component---src-pages-thrketupatlebaran-spinketupat-index-js" */),
  "component---src-pages-thrketupatlebaran-thankyou-index-js": () => import("./../../../src/pages/thrketupatlebaran/thankyou/index.js" /* webpackChunkName: "component---src-pages-thrketupatlebaran-thankyou-index-js" */),
  "component---src-pages-thrlebaran-index-js": () => import("./../../../src/pages/thrlebaran/index.js" /* webpackChunkName: "component---src-pages-thrlebaran-index-js" */),
  "component---src-pages-thrlebaran-spin-index-js": () => import("./../../../src/pages/thrlebaran/spin/index.js" /* webpackChunkName: "component---src-pages-thrlebaran-spin-index-js" */),
  "component---src-pages-thrlebaran-thankyou-index-js": () => import("./../../../src/pages/thrlebaran/thankyou/index.js" /* webpackChunkName: "component---src-pages-thrlebaran-thankyou-index-js" */),
  "component---src-pages-wishforhome-faq-index-js": () => import("./../../../src/pages/wishforhome/faq/index.js" /* webpackChunkName: "component---src-pages-wishforhome-faq-index-js" */),
  "component---src-pages-wishforhome-index-js": () => import("./../../../src/pages/wishforhome/index.js" /* webpackChunkName: "component---src-pages-wishforhome-index-js" */),
  "component---src-pages-wishforhome-kavling-index-js": () => import("./../../../src/pages/wishforhome/kavling/index.js" /* webpackChunkName: "component---src-pages-wishforhome-kavling-index-js" */),
  "component---src-pages-wishforhome-kavling-thankyou-index-js": () => import("./../../../src/pages/wishforhome/kavling/thankyou/index.js" /* webpackChunkName: "component---src-pages-wishforhome-kavling-thankyou-index-js" */),
  "component---src-pages-wishforhome-location-index-js": () => import("./../../../src/pages/wishforhome/location/index.js" /* webpackChunkName: "component---src-pages-wishforhome-location-index-js" */),
  "component---src-pages-wishforhome-thankyou-index-js": () => import("./../../../src/pages/wishforhome/thankyou/index.js" /* webpackChunkName: "component---src-pages-wishforhome-thankyou-index-js" */),
  "component---src-pages-wvc-2023-index-en-js": () => import("./../../../src/pages/wvc2023/index.en.js" /* webpackChunkName: "component---src-pages-wvc-2023-index-en-js" */),
  "component---src-pages-wvc-2023-register-index-en-js": () => import("./../../../src/pages/wvc2023/register/index.en.js" /* webpackChunkName: "component---src-pages-wvc-2023-register-index-en-js" */),
  "component---src-pages-wvc-2023-submission-index-en-js": () => import("./../../../src/pages/wvc2023/submission/index.en.js" /* webpackChunkName: "component---src-pages-wvc-2023-submission-index-en-js" */),
  "component---src-templates-case-study-detail-js": () => import("./../../../src/templates/case-study-detail.js" /* webpackChunkName: "component---src-templates-case-study-detail-js" */),
  "component---src-templates-news-details-amp-js": () => import("./../../../src/templates/news-details.amp.js" /* webpackChunkName: "component---src-templates-news-details-amp-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/news-details.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-project-by-location-infiniteliving-js": () => import("./../../../src/templates/project-by-location-infiniteliving.js" /* webpackChunkName: "component---src-templates-project-by-location-infiniteliving-js" */),
  "component---src-templates-project-by-location-moveinquickly-js": () => import("./../../../src/templates/project-by-location-moveinquickly.js" /* webpackChunkName: "component---src-templates-project-by-location-moveinquickly-js" */),
  "component---src-templates-project-by-propertytype-infiniteliving-js": () => import("./../../../src/templates/project-by-propertytype-infiniteliving.js" /* webpackChunkName: "component---src-templates-project-by-propertytype-infiniteliving-js" */),
  "component---src-templates-project-by-propertytype-moveinquickly-js": () => import("./../../../src/templates/project-by-propertytype-moveinquickly.js" /* webpackChunkName: "component---src-templates-project-by-propertytype-moveinquickly-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/project-detail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */),
  "component---src-templates-project-detail-new-js": () => import("./../../../src/templates/project-detail-new.js" /* webpackChunkName: "component---src-templates-project-detail-new-js" */),
  "component---src-templates-sustainability-detail-js": () => import("./../../../src/templates/sustainability-detail.js" /* webpackChunkName: "component---src-templates-sustainability-detail-js" */)
}

